import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import { Layout } from '../components/Layout'

export const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      404
    </Layout>
  )
}

export default NotFoundPage
